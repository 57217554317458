@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.primary {
  background-color: #303f9f;
  color: #fff;
}

.primary-light {
  background-color: #666ad1;
  color: #fff;
}

.primary-dark {
  background-color: #001970;
  color: #fff;
}

.secondary {
  background-color: #c5cae9;
  color: #000;
}

.secondary-light {
  background-color: #f8fdff;
  color: #000;
}

.secondary-dark {
  background-color: #9499b7;
  color: #000;
}

html,
body {
  font-size: 12px;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  font-family: sans-serif;
}
#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

div[data-rmiz-modal-content="true"] {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: auto !important;
    height: auto !important;
    max-width: 20em;
    max-height: 20em;
    display: flex;
    border-radius: 0 !important;
    background-size: cover;
    flex-shrink: 0;
  }
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #f0f0f0;
  max-height: 100%;
}

.App-header {
  background-color: #222;
  padding: 0.5em;
  color: white;
}

.App-title {
  font-size: 1.5em;
  margin: 0;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
  overflow: auto;
}

.main > .content {
  flex: 1 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  position: absolute;
  top: 0;
  z-index: 1;
  overflow: auto;
  left: 2.7em;
  right: 0;
  bottom: 0;
}

.content.auth {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
div.login {
  width: 24em;
  display: flex;
  flex-direction: column;
  padding: 2em;
  background: #fff;
  box-shadow: 0 2px 5px -3px rgba(0, 0, 0, 0.5);
}
div.login input {
  line-height: 2em;
  font-size: 1em;
  padding: 0 0.5em;
  border: none;
  flex: 1;
  background: #ddd;
}
p.error {
  color: #fe3223;
}
div.inputHolder {
  display: flex;
  flex: 1;
  margin: 0.25em 0;
}
div.iconHolder {
  width: 1.5em;
  height: 1.5em;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #336699;
}
.left {
  display: flex;
  flex-direction: column;
  width: 20.4em;
  padding: 0.25em;
  background: #fff;
  max-height: 100%;
  overflow: hidden;
}
.right {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  overflow: hidden;
  position: relative;
}

.DayPicker {
  z-index: 1;
  margin: 0.3em auto;
}
.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 3em !important;
  z-index: 2;
  padding: 0 1em !important;
  text-align: left;
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 0 0.5em;
  padding-left: 0;
  padding-right: 0;
  font-size: 1em !important;
}

.DayPicker_weekHeader_li {
  width: 2em !important;
  display: inline-block;
  text-align: center;
}

.DayPickerNavigation_button__horizontal {
  border-radius: 0.25em;
  padding: 0.25em;
  top: 1em;
  position: absolute;
}

.DayPickerNavigation_leftButton__horizontal {
  left: 2em;
}

.DayPicker_transitionContainer {
  width: 20em !important;
  height: 20em !important;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
.select-user {
  z-index: 2;
  text-align: left;
  margin: 0.125em;
  flex: 1;
}

.buttons {
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 0.125em 0;
  height: 2.7em;
}
.buttons + .buttons {
  padding-top: 0;
}
.request-params-holder,
.params-table-holder {
  display: flex;
  margin-top: 0.125em;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  max-height: 100%;
  margin-bottom: 0.25rem;
}
.data-viewer.gallery {
  .data-module {
    flex-wrap: wrap;
    overflow: auto;
    flex: none;
    max-height: 100%;

    .feed-image {
      width: 20em;
      height: 20em;
      display: flex;
      border-radius: 0;
      background-size: cover;
      flex-shrink: 0;
      position: relative;
    }
    .card-datetime {
      margin-bottom: 0.5em;
    }
    .user {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 0.25em;
        fill: #336699;
      }
    }
    .point-item.box.even {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
    }
    .point-code {
      transition: 0.3s linear all;
      margin-right: 0.25rem;
      height: 1em;
      line-height: 1em;
      background: #336699;
      font-size: 0.9em;
      border-radius: 0.25em;
      color: #fff;
      cursor: pointer;
      padding: 0.25em;
    }
    .feed-image.solo {
      border-radius: 0.5em;
    }
  }

  .feed-image-card {
    min-height: 25em;
    max-width: 20em;
    padding-bottom: 0.25em;
  }
  .params-table-holder {
    z-index: 1;
  }
  .empl-holder {
    z-index: 2;
  }
}
.request-params-holder {
  flex-direction: column;
}
#root .params-table-holder table.manager-table {
  width: 100%;
  height: auto;
  overflow: auto;
  max-height: 100%;
  flex-shrink: 1;
  display: block;
}
.search-input {
  display: flex;
  flex: 1 1;
  line-height: 1em;
  margin: 0.125em;
  padding: 0 0.25em 0 2.5em;
  border: 0.0625em solid #ccc;
  outline: none;
  box-sizing: border-box;
  min-width: 2em;
  color: #333;
  border-radius: 0.25em;
}
.input-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 2em;
  width: 1.9em;
  font-size: 1.3em;
  padding: 0;
  color: #333;
  border-radius: 0.5em 0 0 0.5em;
  height: 1.9em;
  box-sizing: border-box;
  border: none;
  margin: 0.1em -2em 0.125em 0.125em;
  z-index: 2;
}
.button {
  margin: 0.125em;
  font-size: 1em;
  cursor: pointer;
  background: #fff;
  border: none !important;
  color: #333;
  transition: 0.2s linear all;
  flex: 1;
  border-radius: 0.25em;
  align-items: center;
  justify-content: center;
  display: flex;
}

.button.icon-only {
  width: 1.6em;
  -ms-flex: none;
  height: 1.4em;
  flex: none;
  padding-right: 0;
  background: #ccc;
}
.button.inline {
  background: #f0f0f0;
}

.button.approve {
  background: #e1e1e1;
}

.button.onapproval {
  background: #d6e8f6;
  flex-grow: 1.3;
}

.button.delete {
  background: #484848;
  color: #fff;
}
.button.dload {
  background: #484848;
  color: #fff;
  background: #484848;
  color: #fff;
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
}

.button.success {
  background: #559977;
  color: #fff;
}

.button.success:hover {
  background: #339955;
  color: #fff;
}

.button.error {
  background: #bb3333;
  color: #fff;
}

.button.error:hover {
  background: #cf2222;
  color: #fff;
}

.button.disabled:hover {
  background: #ccc;
  color: #fff;
  border: 1px solid #e4e7e7;
}

.button.active {
  background: #6699cc;
  border: 1px solid #336699;
  color: #fff;
}

.button:hover {
  background: #336699;
  border: 1px solid #336699;
  color: #fff;
}

.button.disabled {
  background: #ccc;
  color: #fff;
}

.button.hidden {
  display: none;
}

.point-list-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.point-list-content .items {
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  margin: 0.125em;
  border: 1px solid #ccc;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 0.25em;
}

.CalendarDay {
  width: 2.2em !important;
  height: 2.2em !important;
  font-size: 1em;
}
.CalendarDay__selected_span {
  background: #6699cc;
  border: 1px solid #336699;
  color: #fff;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #336699;
  border: 1px solid #336699;
  color: #fff;
}

.CalendarDay__selected_span:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: #000;
}

.CalendarDay__default:hover {
  background: #336699;
  border: 1px double #336699;
  color: #fff;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

.DayPicker__withBorder {
  border: 0.0625em solid #ccc !important;
  border-color: #d9d9d9 #ccc #b3b3b3;
  box-shadow: none;
  border-radius: 0;
}

.CalendarMonth_caption {
  font-size: 1em;
  text-align: center;
  padding-top: 1.5em;
  padding-bottom: 2em;
  caption-side: initial;
  color: #6598cd;
}
.CalendarMonth_caption strong {
  font-weight: normal;
}

input.point-search {
  margin: 0.125em;
  padding: 0 0.5em;
  line-height: 2.4em;
  font-size: 1em;
  background: #e2e2e2;
  border: none;
  border-radius: 0.25em;
  color: #333;
  outline: none;
}
input.point-search ::placeholder {
  color: #999;
}

.plan {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.plan .timescale {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.plan .timescale .hour {
  flex: 1;
  flex-direction: row;
  display: flex;
  border-top: 1px solid #ccc;
  position: relative;
}
.plan .timescale .hour .hour-title {
  position: absolute;
  left: -3em;
  top: -0.5em;
  color: #6598cd;
  line-height: 1em;
  font-size: 1em;
  vertical-align: center;
  text-align: center;
  width: 3em;
}
.empty-plan {
  margin: 5em;
  font-size: 2em;
  font-weight: 100;
  color: #999;
  text-align: center;
  flex: 1;
}
.plan .plan-week {
  flex: 1;
  flex-direction: row;
  display: flex;
}

.plan .plan-day {
  flex: 1;
  flex-direction: column;
  display: flex;
  background: #fff;
}
.plan .plan-day.odd {
  background: #fafafa;
}

.plan .plan-day .day-content.can-drop {
  background: #ddddddab;
}
.plan .content {
  overflow: visible;
  position: relative;
  margin-left: 3em;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ccc;
}
.plan .header {
  margin: 0;
  padding: 0;
  color: #fff !important;
  margin-left: 3em;
  display: flex;
  flex-direction: row;
}
.plan .header .day-header {
  /* border: 1px solid #346699; */
  font-size: 1em;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 0.375em 0;
  background: #f0f0f0;
  color: #72a1d2;
}
.plan .header .day-header > div {
  padding: 0 0.25em;
}

.plan .plan-day .day-content {
  display: flex;
  /* background: #eee; */
  position: relative;
  flex: 1;
  flex-direction: column;
}

.plan .plan-day .day-content .plan-item {
  font-size: 1em;
  border-bottom: 1px solid #ddd;
  position: absolute;
  /*width: 100%;*/
  width: calc(100% - 0.5em) !important;
  display: flex;
  overflow: hidden;
  margin: 0 2px;
  background: #fafafaad;
  box-sizing: border-box;
  border: 2px solid transparent;
  /*border-radius: 0.25em;*/
}
.plan .plan-day .day-content .plan-item .draggable-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-top: 2px solid #a9a9a9;
  border-bottom: 2px solid #a9a9a9;
}
.plan .plan-day .day-content .plan-item.selected {
  border: 2px solid #6598cdad;
}
.plan .plan-day .day-content .plan-item.fact-selected {
  border: 2px solid #55cc55ff;
}

.plan .plan-day .day-content .plan-item.preview {
  background: #6598cdad;
  color: #fff;
}
.plan .plan-day .day-content .plan-item.preview.red {
  background: #ff7678;
}

.plan .plan-day .day-content .plan-item.done {
  background: #ccffccad;
}

.plan .plan-day .day-content .plan-item.on-approval {
  background: #d6e8f6ad;
}
.plan .plan-day .day-content .plan-item.approved {
  background: #e1e1e1ad;
}
.plan .plan-day .day-content .plan-item.missed {
  background: #ffccccad;
}
.plan .plan-day .day-content .plan-item.created {
  background: #ffffffad;
}

.plan .plan-day .day-content .plan-item > div {
  padding: 0.25em;
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.points-preloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;

  div {
    width: 3em;
    height: 3em;
    border: 3px solid #dadada;
    border-top-color: #6699cc;
    border-bottom-color: #6699cc;
    border-radius: 50%;
    animation: rotate 0.7s infinite ease-in-out;
  }

  &.points-preloader.small {
    position: relative;
    div {
      width: 1em;
      height: 1em;
      border: 0.125em solid #dadada;
      border-top-color: #6699cc;
      border-bottom-color: #6699cc;
      border-radius: 50%;
      animation: rotate 0.7s infinite ease-in-out;
    }
  }
}

.button-preloader {
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0.5em 0 0;
  vertical-align: bottom;
}
.button-preloader > div {
  width: 0.7em;
  height: 0.7em;
  border: 0.125em solid rgba(0, 0, 0, 0.5);
  border-top-color: rgba(255, 255, 255, 0.5);
  border-bottom-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  animation: rotate 0.7s infinite ease-in-out;
}

.Select-control {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 0.25em;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 2.5em;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 2.5em;
  padding-left: 1em;
  padding-right: 1em;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Select-input {
  height: 2em;
  padding-left: 1em;
  padding-right: 1em;
  vertical-align: middle;
}

.Select-input > input {
  width: 100%;
  background: none transparent;
  border: 0 none;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  line-height: 2.5em;
  padding: 0;
  -webkit-appearance: none;
}
.Select.is-focused:not(.is-open) > .Select-control {
  border-color: #336699;
  box-shadow: none;
  background: #fff;
}

.planning-point-label {
  vertical-align: middle;
  /* font-size: 125%; */
  color: #404040;
}

.content > .info-no-module {
  text-align: center;
  flex: 1;
  color: #336699;
  font-weight: 100;
  font-size: 2em;
  padding: 4em;
}
.planning-event-time-start {
  /* font-size: 125%; */
  color: #404040;
  text-align: left;
}

.planning-event-time-finish {
  /* font-size: 125%; */
  color: #404040;
  text-align: left;
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
}

span.check {
  font-size: 120%;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: 100%;
}

.leftBar {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 2.7em;
  background: #e1e1e1;
  z-index: 3;
  border-right: 0.0625em solid #d5d5d5;
  transition: 0.1s linear all;
}

.leftBarButton > svg {
  padding-top: 0.25em;
  width: 1.7em;
  flex-shrink: 0;
  margin-right: 0.5em;
}
.leftBar:hover {
  transition-delay: 0.5s;
  width: 12em;
}
.leftBarButton {
  padding: 0.5em;
  height: 1.5em !important;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  text-align: left;
  margin: 0;
  flex: none;
}
.button.leftBarButton {
  margin: 0;
  border-radius: 0;
  box-shadow: 0 0 0.25em -0.25em #000 !important;
}

/* .leftBarButton:hover {
  width: auto;
} */
/* .leftBarButton > .line {
  display: none;
} */
.leftBar .leftBarButton {
  transition: 0.1s linear all;
  justify-content: left;
}
.leftBar .leftBarButton > .line {
  transition: 0.1s linear all;
  display: inline-block;
  margin: 0 0.5em;
}

.mapHolder {
  display: flex;
  flex: 1;
  position: relative;
}
.leaflet-container {
  display: block;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

.info-holder {
  position: absolute;
  right: 0;
  /* top: 0; */
  bottom: 0;
  width: 350px;
  z-index: 3;
}

.info-holder .card {
  margin: 0.5em;
  cursor: initial;
}

.info-holder .card .card-container .date {
  padding: 0.5em;
  color: #333;
  font-size: 1em;
  font-weight: 100;
  background: #e1e1e1;
  display: flex;
  cursor: default;
}

.info-holder .card .card-container .date .name {
  flex: 1;
}

.info-holder .card .card-container .date .close,
.overlay .modal-user-picker .modal-header .close {
  cursor: pointer;
}
.info-holder .card .card-container .date .close:hover,
.overlay .modal-user-picker .modal-header .close:hover {
  color: #f53;
}

.modal-content {
  margin-bottom: 3.5em;
}

.cards-holder {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* height: 0; */
  flex-direction: row;
  z-index: 20;
  display: flex;
  flex: 1;
  align-items: flex-end;
}

.card {
  display: flex;
  margin: 0 0 0.3em 0.3em;
  background: #fff;
  border-radius: 0.3em;
  -ms-flex: 1 1;
  cursor: pointer;
  -webkit-box-shadow: 0 0 13px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 13px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.card > .left {
  width: 0.3em;
  padding: 0;
  margin: 0;
}

.card-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.card-container .employee {
  padding: 0.5em;
  color: #fff;
  font-size: 1.5em;
}

.card-container .date {
  padding: 0.5em;
  color: #fff;
  font-size: 1em;
  font-weight: 100;
}

.card-container table.plan-table,
.card-container table.fact-table {
  width: 100%;
  border-collapse: collapse;
}

.card-container table.plan-table td,
.card-container table.fact-table td {
  border: 1px solid #999;
  padding: 0.2em;
  display: table-cell;
  text-align: left;
}

.card-container .legend {
  display: flex;
  flex: 1;
  margin-left: 2em;
}
.card-container .legend div {
  flex: 1 1;
  text-align: right;
  padding: 0.5em;
  font-weight: 100;
  border-bottom: 1px solid #eee;
}
.card-container .legend div:last-child {
  text-align: left;
  border-left: 1px solid #eee;
}
.card-container .day-content {
  display: flex;
  flex-direction: row;
  padding-left: 2em;
  position: relative;
}
.card-container .day-content .scale {
  width: 100%;
  height: 100%;
  text-align: left;
  margin-left: -1.7em;
  position: absolute;
  z-index: 0;
}
.card-container .day-content .scale .hour {
  position: absolute;
  box-sizing: border-box;
  font-size: 0.5em;
  color: #999;
  border-bottom: 1px dashed #ccc;
  width: 100%;
}

.card-container .day-content .scale .end {
  position: absolute;
  box-sizing: border-box;
  font-size: 0.5em;
  border-bottom: 1px solid #f75;
  width: 100%;
}
.card-container .day-content .scale .start {
  position: absolute;
  box-sizing: border-box;
  font-size: 0.5em;
  border-bottom: 1px solid #7f5;
  width: 100%;
}
.card-container .day-content .plans,
.card-container .day-content .facts {
  flex: 1;
  position: relative;
  z-index: 1;
  border-left: 1px solid #eee;
}
.card-container .day-content .plans .plan,
.card-container .day-content .facts .fact {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  border: 0.3em solid transparent;
}
.card-container .day-content .plans .plan .point-name,
.card-container .day-content .facts .fact .point-name {
  padding: 0 0.3em;
  font-size: 0.8em;
  font-weight: 100;
}
.card-container .day-content .facts .fact {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  border-right: 1em solid rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.card-container .day-content .plans .plan {
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  border-left: 1em solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.card-container .day-content .plans .plan.done {
  border-left-color: #ccffccad;
}
.card-container .day-content .plans .plan.on-approval {
  border-left-color: #d6e8f6ad;
}
.card-container .day-content .plans .plan.approved {
  border-left-color: #e1e1e1ad;
}
.card-container .day-content .plans .plan.missed {
  border-left-color: #ffccccad;
}
.card-container .day-content .plans .plan.created {
  border-left-color: #ffffffad;
}

.card-container .day-content .facts .fact.done {
  border-right-color: #ccffccad;
}
.card-container .day-content .facts .fact.on-approval {
  border-right-color: #d6e8f6ad;
}
.card-container .day-content .facts .fact.approved {
  border-right-color: #e1e1e1ad;
}
.card-container .day-content .facts .fact.missed {
  border-right-color: #ffccccad;
}
.card-container .day-content .facts .fact.created {
  border-right-color: #ffffffad;
}

.mapHolder .addUser {
  position: absolute;
  cursor: pointer;
  left: 3.5em;
  top: 0.5em;
  background: #fff;
  z-index: 2;
  font-size: 1.5em;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
  width: 2em;
  line-height: 1.85em;
  border-radius: 1em;
  text-align: center;
  box-shadow: 0 2px 5px -3px rgba(0, 0, 0, 0.7);
  transition: 0.3s linear all;
}
.tree-item.box.header {
  color: #000;
  line-height: 1em;
}
.tree-item.box.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.mapHolder .addUser:hover {
  background: #336699;
  color: #fff;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
}
.overlay .modal-user-picker {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -10em;
  margin-top: -17em;
  width: 20em;
  background: #fff;
  border-radius: 0.25em;
  box-shadow: 0 2px 7px -3px rgba(0, 0, 0, 0.7);
}
.overlay .modal-user-picker > .button.inline {
  border-radius: 0 0 0.25em 0.25em;
  height: 2.5em;
}
.item-has-audit {
  position: absolute;
  top: 0.25em;
  right: 0.125em;
}
.modal-content .empl-holder {
  margin: 1em 1.5em 0;
  /* border-left: none; */
}
.modal-content .Select div {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0.2em;
}
.modal-content .picker-holder {
  flex-direction: column;
  display: flex;
}
.modal-content .picker-holder input.date-holder {
  margin: 0 1.5em;
  flex: 1;
  display: flex;
  padding: 0 0.5em;
  font-size: 1em;
  line-height: 2em;
  border-radius: 0.25em;
  border: 1px solid #ccc;
  font-weight: 100;
}
.modal-user-picker .modal-error {
  color: #dd5511;
  font-size: 0.9em;
  line-height: 2em;
}

.DayPicker {
  border: none;
  width: 20em !important;
  height: 20em !important;
}

.DayPicker > div > div {
  width: 20em !important;
  height: 20em !important;
}

.DayPickerNavigation_svg__horizontal {
  height: 1em;
  width: 1em;
  fill: #82888a;
}

.modal-user-picker > .button.inline {
  position: absolute;
  bottom: 0;
  height: 2.3em;
  line-height: 2.3em;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.overlay .modal-user-picker .modal-header {
  height: 2.5em;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #e1e1e1;
  font-size: 1em;
  font-weight: 100;
  padding: 0 0.7em 0 1em;
  border-radius: 0.25em 0.25em 0 0;
}
.leaflet-marker-icon .marker {
  position: relative;
}
.leaflet-marker-icon .marker .marker-index.pin {
  position: absolute;
  background: #fff;
  left: 0.95em;
  top: 0.75em;
  width: 1.5em;
  height: 1.5em;
  z-index: 2;
  line-height: 1.6em;
  border-radius: 1em;
  opacity: 1 !important;
}
.leaflet-marker-icon .marker .marker-index.flag {
  position: absolute;
  color: #fff;
  left: 13px;
  top: 10px;
  width: 0.9em;
  height: 0.7em;
  z-index: 2;
  line-height: 0.8em;
  border-radius: 1em;
  opacity: 1 !important;
}
div.leaflet-pane.leaflet-popup-pane {
  display: none !important;
}

div.audits-holder {
  display: flex;
  flex-direction: row;
  flex: 1;
  background: #fff;
  overflow: auto;
}
div.audits-holder > div {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

#root table.manager-table {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
#root table.manager-table .rt-th,
#root table.manager-table .rt-td {
  padding: 0.5em 0.3em;
  line-height: 1em;
}
#root table.manager-table .rt-thead.-header {
  box-shadow: none;
  border-bottom: 1px solid #ddd;
}
#root table.manager-table .rt-table {
  -ms-flex: auto 1;
  flex: auto 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  max-height: 100%;
}

#root table.manager-table.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: #336699;
  color: white;
}

#root table.manager-table .-pagination .-pageJump input {
  width: 30px;
  text-align: center;
  padding: 0.2em;
}

#root table.manager-table .rt-thead .rt-th.-cursor-pointer,
#root table.manager-table .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
  outline: none;
}
#root table.manager-table .rt-td {
  text-align: left;
}

#root table.manager-table .rt-td input {
  height: 1em;
  line-height: 1em;
  margin: 0;
  padding: 0;
}

#root table.manager-table .rt-tr:not(.-padRow) .rt-td.monitoring-report {
  background-color: #336699;
  color: #fff;
}

#root table.manager-table .rt-tr:not(.-padRow) .rt-td.page-report {
  background-color: #222;
  color: #fff;
}

#root
  table.manager-table
  .rt-tr:not(.-padRow)
  .rt-td.monitoring-report
  .rt-expander:after,
#root
  table.manager-table
  .rt-tr:not(.-padRow)
  .rt-td.page-report
  .rt-expander:after {
  border-top-color: #fff !important;
}
.inner-holder > #root table.manager-table {
  display: flex;
  flex-direction: column;
  flex: initial;
}

.tab-correct {
  display: inline-block;
  margin: 0 0 0 0.5em;
  line-height: 0.8em;
  vertical-align: bottom;
  color: #22ab00;
}

li.react-tabs__tab--selected .tab-correct {
  color: #fff;
}

.info-icon-tab {
  display: inline-block;
  margin: 0 0.5em 0 0;
  line-height: 0.8em;
  vertical-align: bottom;
  color: #336699;
}

li.react-tabs__tab--selected .info-icon-tab {
  color: #fff;
}

div.audits-holder > div.audit-fact-holder > div.tabs-holder {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-left: 1px solid #e1e1e1;
}

div.audit-correct,
div.audit-all-correct,
div.audit-not-correct {
  color: #22ad00;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 1.5em;
}
div.audit-all-correct {
  color: #336699;
}
div.audit-not-correct {
  color: #ff7678;
}

div.audits-holder div.tabs-holder > div.tabs {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
li.react-tabs__tab {
  padding: 0.5em;
  background: #fff;
  margin-right: 0.25em;
  /* margin: 0.5em 0125em 0 0; */
}
li.react-tabs__tab--selected {
  border-radius: 0;
  border: none;

  border-bottom: none;
  margin-top: 0.5em;
  background: #336699;
  color: #fff;
}
.react-tabs__tab-list {
  border-bottom: 1px solid #eee;
  margin: 0;
  padding: 0;
  text-align: left;
  padding-left: 0.25em;
}

div.react-tabs__tab-panel--selected {
  display: flex;
  flex: 1;
  background: #fff;
  overflow: hidden;
}
div.react-tabs {
  flex: 1;
  display: flex;
  background: #e1e1e1;
  overflow: hidden;
  flex-direction: column;
  -webkit-tap-highlight-color: transparent;
}
div.quest-info-holder {
  height: 100%;
  flex-direction: column;
  display: flex;
  flex: 1;
  overflow: auto;
}
div.quest-info-item {
  margin: 1em;
  padding: 1em;
  background: #fff;
  box-shadow: 0 1px 5px -2px rgba(0, 0, 0, 0.5);
}

div.header {
  display: block;
  margin: -1em -1em 1em -1em;
  padding: 0.5em;
  background: #336699;
  color: #fff;
}

.quest-item div.header {
  margin-top: 1em;
}
.quest-item div.header.quest {
  margin-top: -1em;
  background: #333;
}

div.infoField {
  margin: 0.5em 0;
  /* padding: 0.5em; */
  display: flex;
  flex: 1;
  flex-direction: row;
  text-align: left;
  background: #aaa;
  color: #fff;
}
div.infoField:last-child {
  margin-bottom: 0;
}
div.infoField > div {
  display: flex;
  padding: 0.5em;
  line-height: 1em;
  justify-content: center;
  flex: 1;
  flex-direction: column;
}

div.infoField > div.label {
  max-width: 15em;
}

div.value .list-value {
  padding: 0.25em 0;
  display: list-item;
  margin-left: 1.3em;
  text-align: left;
}

div.value .photo-value img,
div.audit-value .photo-value img {
  width: 30em;
  display: block;
  background: #ddd;
}
div.audit-value {
  background: #336699;
}
div.quests-holder {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1em;
  overflow: auto;
}

dir.monitoring-item {
  margin: 0;
  padding: 0;
  flex-direction: row;
  flex: 1;
  display: flex;
}

dir.monitoring-item:last-child {
  margin-bottom: 2em;
}
dir.monitoring-item .info {
  max-width: 15em;
}
dir.monitoring-item .info,
dir.monitoring-item .data {
  flex: 1;
  flex-direction: column;
  display: flex;
}

dir.monitoring-item .info div.infoField {
  display: flex;
  flex: 1;
  flex-direction: column;
}

dir.monitoring-item .info div.infoField > div {
  flex: none;
}

dir.monitoring-item .info div.infoField > div.label-small {
  font-size: 0.7em;
  padding-top: 0;
}

dir.monitoring-item .data {
  background: #fff;
  padding: 0.5em;
  padding-top: 0;
  margin-top: 0.5em;
  border: 1px solid #aaa;
}

div.infoField.correct {
  background: green;
}

.leftBar .spacer {
  display: flex;
  flex: 1;
}

.leftBar .button.leftBarButton.font {
  /* bottom: 2.5em; */
  /* position: absolute; */
  /* width: 1.5em !important; */
  left: 0;
}

.leftBar .button.leftBarButton.font .sub {
  font-size: 0.6em;
  position: absolute;
  left: 2.2em;
  top: 0;
  transition: 0.1s linear all;
}

.leftBar:hover .button.leftBarButton.font .sub {
  left: 2.35em;
}
.leftBar .button.leftBarButton.font.encrease {
  /* bottom: 2.5em; */
}
.leftBar .button.leftBarButton.font.decrease {
  /* bottom: 0em; */
}

.Pane.vertical,
.audit-fact-holder,
.audit-table-holder,
.tabs-holder {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  -ms-flex: 1 1;
  flex: 1 1;
}
.audit-table-holder {
  // overflow: auto;
  .table-container {
    overflow: hidden;
    .table-holder {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex: 1 1;
      div.table.manager-table {
        flex: 1 1;
        overflow: auto;
        border: 0.0625em solid #ddd;
        box-sizing: border-box;
        min-width: 0 !important;

        .thead {
          overflow: visible;
        }
      }
    }
  }
}
.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.data-viewer,
.monitoring-viewer {
  display: flex;
  overflow: hidden;
  flex: 1;
  flex-direction: row;
  position: relative;
  background: #fff;
}

.request-form {
  border-right: 0.0625em solid #ddd;
  display: flex;
  min-width: 20em;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 0.25em;
  padding-left: 0.25em;
}

.DateInput__small {
  width: 50%;
  overflow: hidden;
}
.DateInput_input__small {
  font-size: 1em;
  line-height: 1em;
  padding: 0.5em;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}
.DateRangePickerInput__withBorder {
  border: 1px solid #cacccd;
  white-space: nowrap;
  border-radius: 0.25em;
}
.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  width: 2em;
}
.DateRangePicker_picker__directionLeft {
  top: 2.7em !important;
  left: 0px;
}
.Select-menu-outer {
  text-align: left;
}

.data-module {
  display: flex;
  flex: 1 1;
  background: #fff;
  overflow: hidden;
  position: relative;
}
#root table.manager-table.-striped .rt-tr.-odd,
.tree-item.box.odd {
  background-color: rgba(0, 0, 0, 0.05);
}
.meta-header {
  color: #3c6897;
  display: inline-block;
}
.base-header {
  padding: 0 0.125em;
  display: inline-block;
}

.base-header > svg,
.meta-header > svg,
.rt-resizable-header-content svg {
  padding-top: 0em;
  height: 1.3em;
  display: inline-block;
  margin-bottom: -0.3em;
}

.request-form > div {
  position: relative;
  z-index: 1;
  border: none;
}

.request-form > div.Select {
  z-index: 3;
}
.request-form > div.from-to-form {
  z-index: 3;
}

.from-to-form .buttons .button.inline {
  white-space: nowrap;
}
.Select-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
}

.button.leftBarButton.current {
  background: #95a8bc;
  border: 1px solid #95a8bc;
  color: #fff;
}

.from-to-form .DateRangePickerInput__withBorder {
  border: 0.0625em solid #ccc !important;
  white-space: nowrap;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.from-to-form .DateRangePicker {
  position: relative;
  display: flex;
}
.from-to-form .DateRangePicker > div {
  width: 100%;
}

.tree-component-content {
  background: #fff;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.tree-content {
  background: #fff;
  flex: 1;
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;

  .filters {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 3;
    border-bottom: 1px solid #ccc;
  }

  .items {
    border: 1px solid #ddd;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0.25em;
    padding-left: 0.5em;

    .children {
      display: flex;
      flex-direction: column;
      padding-left: 1em;
      z-index: 1;
    }

    .tree-item {
      &.box {
        line-height: 1.5em;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        position: relative;
        overflow: visible;
        transition: 0.3s linear all;

        &:not(:last-child)::after {
          content: "";
          width: 1px;
          position: absolute;
          left: -0.3em;
          bottom: 0.74em;
          height: 100%;
          background: #ccc;
          z-index: 0;
        }

        .item-content {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          white-space: nowrap;
          z-index: 2;
          align-items: center;

          .point-name {
            padding: 0 0.25em;
            line-height: 2em;
          }

          .user-name {
            line-height: 2em;
          }

          .point-code {
            transition: 0.3s linear all;

            height: 1em;
            line-height: 1em;
            background: #336699;
            font-size: 0.9em;
            border-radius: 0.25em;
            color: #fff;
            cursor: pointer;
            padding: 0.25em;
          }

          .icon-holder {
            width: 1.5em;
            height: 1.5em;
            line-height: 2em;
            border-radius: 1em;
            font-size: 1em;
            text-align: center;
            cursor: pointer;
            color: #336699;
            position: relative;
            flex-shrink: 0;

            &::before {
              content: "";
              width: 0.6em;
              position: absolute;
              left: -0.3em;
              bottom: 0.74em;
              height: 1px;
              background: #ccc;
            }

            &::after {
              content: "";
              width: 1px;
              position: absolute;
              left: -0.3em;
              bottom: 0.74em;
              height: 120%;
              background: #ccc;
              z-index: 0;
            }

            svg {
              background: #eee;
              border-radius: 0.25em;
              border: 0.0625em solid #ccc;
              position: relative;
            }
          }
        }

        &.selected {
          border-radius: 0.25em;
          padding: 0 0.25em;
          background: #336699;
          color: #fff;

          .point-code {
            color: #336699;
            background: #e2e2e2;
          }
        }
      }
    }
  }
}
/* ========================================Fields=================================================== */
$input-light-color: #ddd;
$input-border-color: #777;
$input-main-color: #336699;
$input-valid-color: #559977;
$input-invalid-color: #bb3333;

.input-div {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0.25rem;

  &.table {
    .input-holder {
      .table-input-holder {
        // overflow-x: auto;
      }
    }
  }

  .label-holder {
    .label {
      color: $input-border-color;
      text-align: left;
    }
  }
  .input-holder {
    display: flex;
    flex: 1 1;
    margin: 0.25em 0;

    .icon-holder {
      width: 1.75em;
      height: 1.75em;
      font-size: 1.5em;
      border-radius: 0.25rem 0 0 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: $input-light-color;
      color: $input-main-color;
      flex-shrink: 0;

      &.plain {
        border-radius: 0;
        border-left: 0.0625rem solid #efefef;
        cursor: pointer;

        &:hover {
          background-color: #0074d9;
          color: #fff;
        }
      }

      &.bool {
        border-radius: 0.25em;
      }
    }

    .select-field {
      .select__control {
        border: 0.0625rem solid $input-light-color !important;
      }
    }

    input,
    textarea {
      border: 0.0625rem solid $input-light-color;
      background-color: #fff;
      color: #333;
      border-radius: 0 0.25rem 0.25rem 0;
      padding: 0 0.5rem;
      flex: 1;
      font-size: 1.2em !important;
      font-weight: 100 !important;
      font-family: Helvetica, Roboto, Arial, sans-serif;

      &:focus {
        outline: none;
      }
    }
    textarea.text {
      min-width: 0;
      overflow: auto;
      resize: vertical;
      // white-space: nowrap;
      width: 100%;
      border-radius: 0 0.25rem 0.25rem 0.25rem;
      min-height: 4.5em;
    }
  }

  &.valid {
    // > div.input-holder {
    //   .icon-holder {
    //     background: $input-valid-color;
    //   }
    //   input,
    //   textarea,
    //   .image-input-holder,
    //   .table-input-holder,
    //   .select__control {
    //     border: 0.0625rem solid $input-valid-color !important;
    //   }
    // }
  }
  &.invalid {
    > .label-holder {
      .label {
        color: $input-invalid-color;
      }
    }
    > .input-holder {
      .icon-holder {
        background: $input-invalid-color;
        color: white;
      }
      input,
      textarea,
      .image-input-holder,
      .table-input-holder,
      .select__control {
        border: 0.0625rem solid $input-invalid-color !important;
      }
      .select-field {
        .select__control {
          border: 0.0625rem solid $input-invalid-color !important;
        }
      }
    }
  }
  &.disabled {
    > .label-holder {
      .label {
        color: $input-border-color;
      }
    }
    > .input-holder {
      .icon-holder {
        color: white;
        background: $input-border-color;
      }
      input,
      textarea,
      .image-input-holder,
      .table-input-holder,
      .select__control {
        border: 0.0625rem solid $input-border-color !important;
      }
      .select-field {
        .select__control {
          border: 0.0625rem solid $input-border-color !important;
        }
      }
    }
  }
}
/* ================================================================================================= */

.point-list-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.point-list-content .items {
  flex-direction: column;
  -ms-flex: 1 1;
  flex: 1 1;
  overflow-y: scroll;
  margin: 0.125em;
  /* border: none; */
  border-color: #d9d9d9 #ccc #b3b3b3;
}

.point-list-content .point-item {
  display: flex;
  font-size: 1em;
  flex-direction: row;
  cursor: pointer;
  border-bottom: 1px solid #e4e7e7;
  background: #fff;
  height: auto;
}

.point-list-content .point-item.header {
  margin: 0.125em 0;
  border: none;
  padding: 0.25em 0;
  z-index: 3;
}
.point-list-content .point-item > div {
  padding: 0.5em;
  border-left: 1px solid #fafafa;
}
.point-list-content .point-item.header div {
  position: relative;
  text-align: left;
}

.point-list-content .point-item:not(.header):hover {
  background-color: #6699cc;
  color: #fff;
}

.point-list-content .point-item .point-index.sorted::after,
.point-list-content .point-item .point-name.sorted::after,
.point-list-content .point-item .point-code.sorted::after {
  content: "";
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  right: 0.5em;
  top: 0.8em;
  border: 0.5em solid transparent;
  border-top: 0.5em solid #ccc;
  border-bottom: none;
  transform: rotate(0deg);
  transition: 0.1s all linear;
}
.point-list-content .point-item .point-index.sorted.desc::after,
.point-list-content .point-item .point-name.sorted.desc::after,
.point-list-content .point-item .point-code.sorted.desc::after {
  transform: rotate(180deg);
}

.point-list-content .point-item .point-index {
  width: 2em;
  border-left: none;
}

.point-list-content .point-item .point-name {
  flex: 1;
  text-align: left;
}

.point-list-content .point-item .point-code {
  width: 5em;
  overflow: hidden;
}

.point-item.box.header {
  color: #000;
  line-height: 1em;
}

#root table.manager-table.-striped .rt-tr.-odd,
.point-item.box.odd {
  background-color: rgba(0, 0, 0, 0.05);
}
.feed-holder {
  flex: 1;
  max-height: 100%;
  background: #fff;
}
.feed-holder .rt-expander.-open {
  display: none;
}
.feed-images-holder {
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  background: #fff;
  flex-wrap: wrap;
}
.feed-image {
  width: 20em;
  height: 20em;
  display: flex;
  border-radius: 0.5em 0.5em 0 0;
  background-size: cover;
  flex-shrink: 0;
}
.feed-image.solo {
  border-radius: 0.5em;
}
.card-title {
  font-size: 1em;
  padding: 0.5em;
  background: #fff;
  border-radius: 0 0 0.5em 0.5em;
}
.feed-image-card {
  cursor: pointer;
  border-radius: 0.5em;
  flex-shrink: 0;
  margin: 0.25em;
  box-shadow: 0 0.1em 0.5em -0.25em #000;
}
.ReactModal__Overlay {
  z-index: 10;
}
.ReactModal__Content {
}

.navbar {
  width: 100%;
  height: 5em;
  background-color: #0074d9;
}

.navbar-fixed-top {
  position: fixed;
  top: 0;
}

.img-fork {
  position: absolute;
  width: 130px;
  top: 0;
  right: 0;
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.navbar-brand {
  color: white;
  font-size: 2rem;
  line-height: 5em;
}

.container {
  top: 50px;
  padding: 2em;
}

.title {
  font-size: 3rem;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  margin-bottom: 1.5rem;
}

.img-list {
  display: flex;
  width: 640px;
  height: 500px;
  margin: 0 auto;
  justify-content: center;
}
.img-list.hide {
  display: none;
}

.img-item {
  width: 20em;
  height: 20em;
  padding: 1px;
}

.img-item > img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.footer {
  position: fixed;
  bottom: 0;
  background-color: #0b2f3d;
  width: 100%;
}
.container-footer {
  padding: 24px;
  text-align: center;
}
.signature {
  color: white;
}
.container {
  position: relative;
}
.inline-container {
  display: none;
  margin: 0 auto;
}
.inline-container.show {
  display: block;
}

.options-list {
  margin-top: 12px;
}
.empl-select-holder {
  display: flex;
  flex-direction: row;
}

.empl-select-holder .button,
.button.solo {
  width: 2.5em;
  flex: none;
}
.button.solo .button-icon-holder {
  margin: 0.25em;
  padding: 0;
}

.button.leftBarButton .button-icon-holder {
  margin: 0 0.16em;
  padding: 0;
}

.button > svg {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.button > svg:not(:last-child) {
  margin-right: 0.25em;
}
.empl-holder {
  background: #fff;
  position: relative;
}
.empl-tree-holder {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  display: flex;
  border-radius: 0 0 0.5em 0.5em;
  height: 40em;
  overflow: hidden;
  padding: 0.5em;
  background: #fff;
  box-shadow: 0 0.0625em 0.25em -0.125em;
}

.user-name.selectable {
  cursor: pointer;
  transition: 0.3s linear all;
  padding: 0 0.5em;
  border-radius: 0.25em;
}
.user-name.selectable:hover {
  color: #336699;
}
.user-name.selectable.selected {
  background: #336699;
  color: #fff;
}
.login .button {
  min-height: 3em;
}

.tree-item.box.content.is-point {
  justify-content: flex-end;
  align-items: center;
  flex-direction: row-reverse;
}
span.button-icon-holder {
  margin-left: 0.5em;
  padding: 0;
  line-height: 0;
  font-size: 1.4em;
}
span.button-text {
  flex: 1;
  padding: 0 0.5em;
}

.table-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-direction: column;
}

.-pagination {
  flex-direction: row;
  display: flex;
  min-height: 3em;

  .-center {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .-pageInfo {
      display: flex;
      justify-content: center;
      align-items: center;

      .-currentPage {
        font-size: 1.3em;
        font-weight: 100;
        margin-right: 0.5em;
      }
      .-totalPages {
        font-size: 1.3em;
        font-weight: 100;
        margin-left: 0.5em;
      }
      .-pageJump {
        input {
          padding: 0em 0.5em;
          width: 3em;
          margin-right: 0.5em;
          line-height: 2em;
          vertical-align: middle;
          border-radius: 0.25em;
          border: 0.0625em solid #ccc;
        }
      }
    }
  }
}
.journals-table-holder {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .params-table-holder {
    margin: 0;
  }
}

.table-holder {
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.table-holder {
  .td {
    .feed-image {
      width: 5em;
      height: 5em;
      overflow: hidden;

      &.solo {
        [data-rmiz-wrap="visible"],
        [data-rmiz-wrap="hidden"] {
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

div.table.manager-table {
  flex: 1 1;
  border: 0.0625em solid #ddd;
  box-sizing: border-box;

  .tr .th,
  .tr .td {
    border-bottom: 0.0625em solid #ddd;
  }

  .sub-holder {
    padding: 0.5em;
    border-bottom: 0.0625em solid #ddd;
    background: #fafafa;
    box-shadow: inset 0 0.5em 0.25em -0.6em;
    overflow: auto;
  }

  .thead {
    width: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;

    .th {
      border-left: 0.0625em solid #ddd;
      display: flex;
      padding: 0.25em;
      justify-content: center;
      align-items: center;
      background: #efefef;

      &:first-child {
        border-left: none;
      }

      .resizer {
        width: 0.5em;
        height: 100%;
        top: 0;
        right: 0;
        margin-right: -0.25em;
        position: absolute;

        &.isResizing {
          background: #336699;
        }
      }
    }
  }
  .td {
    border-left: 0.0625em solid #ddd;
    padding: 0.25em;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;

    div.value,
    div.audit-value {
      .photo-value {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
          width: 100%;
          display: block;
          background: #ddd;
        }
      }
    }
    &:first-child {
      border-left: none;
    }

    &.justify-center {
      justify-content: center;
      text-align: center;
    }
    &.justify-left {
      justify-content: flex-start;
      text-align: left;
    }
    &.justify-right {
      justify-content: flex-end;
      text-align: right;
    }
    &.align-center {
      align-items: center;
    }
    &.align-top {
      align-items: flex-start;
    }
    &.align-bottom {
      align-items: flex-end;
    }
  }
}

.request-form > div.select-quest {
  z-index: 6;

  .manager-select__menu {
    text-align: left;
  }

  .manager-select__control {
    outline: none;
    min-height: 0;
    border-radius: 0.25em;

    .manager-select__indicator.manager-select__dropdown-indicator {
      padding: 0.25em;
    }
    span.manager-select__indicator-separator {
      margin: 0.25em 0;
    }
  }
}

.day-content {
  .drop-target-day {
    flex: 1;
    position: relative;
  }
}
.profile {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.form-holder {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow: hidden;
  margin: 0.5em;

  .form-tabs-holder {
    display: flex;
    flex-direction: row;
    margin-left: 0em;
    position: relative;

    .scroll {
      width: 1em;
      height: 1em;
      bottom: 0;
      font-size: 1.5em;
      display: flex;
      flex: none;
      align-items: center;
      justify-content: center;
      border-radius: 0.25em 0.25em 0 0;
      margin: 0.25em 0.25em 0 0.25em;
      background: transparent;
      padding: 0;
      flex-shrink: 0;
    }

    .scroll-container {
      overflow: hidden;
      scroll-behavior: smooth;
      display: flex;
      flex: 1;
      flex-direction: row;
    }

    .tab {
      font-size: 1.25em;
      line-height: 2em;
      font-weight: 100;
      padding: 0 0.5em;
      margin-right: 0.25rem;
      background: #ddd;
      min-width: 5em;
      border-radius: 0.25em 0.25em 0 0;
      cursor: pointer;
      white-space: nowrap;
      flex-shrink: 0;

      &.current {
        background: #fff;
        color: $input-main-color;
      }

      &.invalid {
        color: #fff;
        background: $input-invalid-color;
      }
    }
  }
  &.has-main {
    .form-tabs-holder {
      margin-left: 25.0625em;
    }
  }
  .buttons-holder {
    height: 3em;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    margin-top: 0.5em;
    justify-content: flex-end;

    .button {
      flex: none;
      min-width: 10em;
    }
  }
  .data-holder {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    overflow: hidden;
    border-top: 0.125em solid #fff;
    transition: 0.3s linear all;

    &.invalid {
      border-top: 0.125em solid $input-invalid-color;
    }

    .main-holder {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 25em;
      border-right: 0.0625em solid #f0f0f0;
    }

    .page {
      background: #fff;
      display: flex;
      flex: 1 1;
      flex-direction: column;
      overflow: auto;
      padding: 1em;

      .bool-body {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-start;
        padding: 0 0.5em;

        .yes-text {
          margin-right: 0.5em;
        }
        .no-text {
          margin-left: 0.5em;
        }
        .switch {
          background: $input-invalid-color;
          border-radius: 2em;
          width: 4em;
          height: 2em;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          transition: 0.3s linear all;

          .handle {
            margin: 0.25em;
            border-radius: 2em;
            width: 1.5em;
            height: 1.5em;
            background: #fff;
            transition: 0.3s linear all;
          }

          &.yes {
            justify-content: flex-start;
            background: $input-valid-color;

            &.disabled {
              background: $input-border-color;
            }
          }
          &.disabled {
            background: $input-border-color;
          }
        }
      }
      .add-line {
        margin: 0.25em;
        padding-left: 2.25em;
        display: flex;
        height: 2.5em;
        justify-content: flex-start;
        flex-direction: row;

        .button {
          flex: none;
          padding-right: 0.25em;
        }
      }

      .table-input-holder {
        flex: 1;
        flex-direction: column;

        .table-container {
          overflow: initial;
          .table-holder {
            overflow: initial;
            .manager-table {
              background: #efefef;
            }
            .num-row {
              padding: 0 0.25em;
            }
            .th {
              min-height: 2.4em;
            }
            .td {
              display: flex;
              align-items: unset;
              justify-content: unset;
              flex-direction: row;
            }
            .meta-header.delete {
              width: 2.75em;
            }
            .button.delete {
              width: 2.5em;
              flex: none;
              height: 2.3em;
            }
            .input-cell {
              display: flex;
              flex-direction: column;
              flex: 1;
              justify-content: flex-start;

              .input-div {
                padding: 0;
                .input-holder {
                  margin: 0;
                }
                .label-holder {
                  display: none;
                }
                input {
                  min-width: 2em;
                  width: auto;
                  flex: 1;
                }
              }
            }
          }
        }
      }

      .image-input-holder {
        width: 20em;
        height: 20em;
        display: flex;
        border-radius: 0 0.25em 0.25em 0.25em;
        color: #f0f0f0;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        .change-image {
          position: absolute;
          left: 0;
          top: 0;
          width: 2.7em;
          height: 2.7em;
          display: flex;
          opacity: 0;
          transition: 0.3s linear all;
        }
        .clear-image {
          position: absolute;
          right: 0;
          top: 0;
          width: 2.7em;
          height: 2.7em;
          display: flex;
          opacity: 0;
          transition: 0.3s linear all;
        }

        &:hover {
          .change-image {
            opacity: 1;
          }
          .clear-image {
            opacity: 1;
          }
        }

        > svg {
          font-size: 15em;
        }

        .input-image {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            border-radius: 0 !important;
            width: auto !important;
            height: auto !important;
            max-width: 100%;
            max-height: 100%;
          }
          button {
            outline: none !important;
          }
        }

        input.file {
          display: none;
        }
      }

      .SingleDatePicker {
        display: flex;
        flex: 1;
        position: relative;

        .DateInput_fang {
          bottom: -10px;
          top: auto !important;
        }
        .SingleDatePicker_picker {
          top: calc(10px + 2.5em) !important;
          left: -3.5em !important;
        }
        .DayPicker {
          margin: 0;
          overflow: hidden;
          width: 19.3em !important;
        }

        > div {
          flex: 1;
          display: flex;

          .SingleDatePickerInput {
            margin: 0 !important;
            padding: 0 !important;
            flex: 1;
            display: flex;

            .DateInput {
              flex: 1;
              display: flex;
              width: 1em;
            }

            .SingleDatePickerInput_clearDate {
              padding: 0;
              width: 2em;
              height: 2em;
              display: flex;
              align-items: center;
              justify-content: center;
              right: 0.25em;
              top: 0.25em;
              margin: 0;
              transform: none;
            }
          }
        }
      }

      .select-field {
        flex: 1;
        outline: none !important;
        border: none;

        input {
          border: none !important;
        }
        .select__menu {
          text-align: left;
        }
        .select__control {
          align-items: center;
          background-color: hsl(0, 0%, 100%);
          border-color: hsl(0, 0%, 80%);
          border-radius: 0 0.25em 0.25em 0;
          border-style: solid;
          border-width: 0.0625em;
          cursor: default;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          min-height: 2em;
          outline: none !important;
          position: relative;
          transition: all 100ms;
          box-sizing: border-box;
        }
        .select__control--is-focused {
          outline: none !important;
          outline-style: none !important;
          box-shadow: none !important;
        }
        .select__indicators {
          .select__indicator {
            color: hsl(0, 0%, 80%);
            display: flex;
            padding: 0.4em;
            height: 2.45em;
            transition: color 150ms;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}
.action-plan-data-module {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: masonry;
  overflow: auto;
  position: relative;
  height: 100%;

  .empty-plan {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30rem;
    margin-left: -15rem;
    margin-top: -2rem;
  }

  .action-plan-card {
    padding: 0.5rem;
    margin: 0.25rem;
    box-shadow: 0 0.0625rem 0.125rem -0.0625rem #000;
    border-radius: 0.25rem;

    .title {
      margin-top: 0.5rem;
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 0;
      border: none;
      text-align: left;
    }

    .point {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .point-code {
        transition: 0.3s linear all;
        height: 1em;
        line-height: 1em;
        background: #336699;
        font-size: 0.9em;
        border-radius: 0.25em;
        color: #fff;
        cursor: pointer;
        padding: 0.25em;
        margin-right: 0.25rem;
      }
    }

    .plan-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0.25rem 0.25rem 0.25rem;
      border-bottom: 0.0625rem solid #eee;

      .activity-name {
        font-weight: 600;
        /* font-size: 1.25rem; */
      }
      .answers-count {
        cursor: help;
        height: 1.5rem;
        line-height: 1.5rem;
        min-width: 1.5rem;
        background: #369;
        border-radius: 0.75rem;
        color: #fff;
        font-size: 0.85rem;
      }
    }
    .plan-body {
      padding: 0.25rem;
      text-align: left;
      .info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.5rem 0;
      }
      .comments {
        text-align: left;
      }
    }
    .button {
      min-height: 2rem;
      margin-top: 0.5rem;
    }
    .answer {
      margin-top: 0.5rem;
      .answer-body {
        .answer-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #bb3333;

          &.complies {
            color: #22ab00;
          }
        }
        .author {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .comment {
          background: #eee;
          border-radius: 0.125rem;
          padding: 0.5rem;
          svg {
            margin-top: 0.125rem;
          }
        }
        div {
          padding: 0 0.25rem;
          text-align: left;
        }
      }
    }
  }
}

.profile-holder {
  z-index: 3;
}
